.expolre-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.explore-inner {
    width: 20%;
    padding: 0 15px;
}
h4.common-heading {
    margin-bottom: 16px;
    display: table;
    position: relative;
    padding: 0;
    font-size: 26px;
    font-weight: 500;
}
.explore-data {
    padding-top: 80px;
}