/*---services--*/
.services {
  padding-top: 80px;
}
  .inner-service {
    display: flex;
    max-width: 620px;
    align-items: center;
  }
  
  .left-service {
    width: 50%;
    padding-right: 12px;
  }
  .right-service {
    width: 50%;
    padding-left:32px;
  }
  .inner-service button.common-btn {
    padding: 12px 30px;
    font-size: 16px;
  }
  .left-service img {
    width: 100%;
    height: 315px;
    object-fit: cover;
    border-radius: 10px;
  }
  .right-service h3 {
    font-size: 22px;
    color: #000c;
    font-weight: 600;
    margin-bottom: 28px;
  }
  .right-service p {
    margin-bottom: 30px;
  }
  .services .head-line {
    padding-bottom: 60px;
  }
  .services-right {
    display: flex;
}
.services-right .inner-ser {
  width: 33.33%;
  float: left;
}
.services-right .inner-ser p {
  font-size: 18px;
  padding-right: 70px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: #000;
  line-height: 22px;
  min-height: 161px;
}
.inner-ser h4 {
  font-size: 21px;
  font-weight: 500;
  margin-top: 21px;
  margin-bottom: 14px;
  font-family: 'Raleway', sans-serif;
}
.services-right {
  display: flex;
  border: 1px solid transparent;
  border-image: linear-gradient(53deg, #dedede , white);
  border-image-slice: 1;
  padding: 30px 40px;
}
h2 {
  font-family: 'Glitten';
  font-size: 80px;
  line-height: 80px;
  margin: 0;
}
.services-left p {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: #21323C;
  font-family: 'Raleway', sans-serif;
}
.services .row {
  align-items: flex-end;
}
.services-right .inner-ser a {
  text-decoration: none;
  font-size: 18px;
  color: #E89732;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}
.services-right .inner-ser a i {
  margin-left: 7px;
  transition: 1s;
}
.services-right .inner-ser a:hover i {
  transition: 1s;
  transform: translateX(20px);
 
}
.services-right .inner-ser a i {
  transition: 1s;
}
.services-right .inner-ser a, .services-right .inner-ser a:hover {
  transition: 1s;
}
.services {
  background-image: url(/static/media/lines.247d9cb7.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 310px;
}
.services-left h2 {
  position: relative;
}
.services-left h2::before {
  content: "";
  position: absolute;
  /* background-image: url(../../assets/images/str.png); */
  width: 16px;
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: -36px;
}