.headerpage {
    padding: 25px 0;
    z-index: 9;
    width: 100%;
    position: relative;
}
.headerpage ul.navbar-nav.me-auto {
    margin: 0 auto;
}
.headerpage ul.navbar-nav.me-auto li a {
    font-size: 18px;
    text-decoration: none;
    margin: 0 20px;
    color: #000;
    position: relative;
}
a.navbar-brand {
    color: #000;
}
.log-btn button {
    font-size: 18px;
    border: 0;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddddddbd;
    padding: 8px 31px;
}
.headerpage ul.navbar-nav.me-auto li a::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 0%;
    transition: 0.5s;
    height: 3px;
    background: #E89732;
    border-radius: 10px;
}
.headerpage ul.navbar-nav.me-auto li a:hover::before {
    width: 100%;
    transition: 0.5s;
}
.banner-page img {
    width: 100%;
}

.banner-page {
    position: relative;
    min-height: 860px;
}
.banner-data {
    position: absolute;
    top: 162px;
    /* transform: translateY(-50%); */
}

.banner-data p {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-style: italic;
    margin: 19px 0 0;
}
.banner-page {
    background-image: url(../../assets/images/banner.png);
    background-repeat: no-repeat;
    background-position: top center;
}
.top-property {
    margin-top: -80px;
}