.property h4 {
    text-align: center;
}
h4 {
    font-size: 40px;
    font-weight: 400;
  }
  .property p.tp-brd {
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    color: #21323C;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: -30px;
    padding-top: 46px;
    margin-bottom: 56px;
}

.property h4 {
    margin-bottom: 16px;
    display: table;
    position: relative;
    padding: 0;
    font-size: 26px;
    font-weight: 500;
}
.inner-propery img {
    width: 100%;
}
.property .col-md-4.center {
    padding: 0;
}
.property .col-md-4 {
    padding: 0 20px;
}
.inner-propery img {
    height: 290px;
    object-fit: cover;
}
.inner-propery h5 {
    color: #4E4439;
    font-size: 21px;
    margin-top: 18px;
    margin-bottom: 13px;
}
.property
{
    font-family: 'Raleway', sans-serif;
}
.inner-propery p {
    font-size: 14px;
    font-weight: 400;
    padding-right: 70px;
}
.inner-propery {
    margin-bottom: 56px;
}
.col-md-4.center .inner-propery img {
    height: 734px;
}
.view-all-btn button {
    margin: 0 auto;
    float: none;
}
.view-all-btn {
    width: 100%;
    text-align: center;
}
.sale-btn {
    position: relative;
}
.sale-btn button {
    background: #000000db;
    color: #fff;
    border: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 21px;
    padding: 15px 0;
    font-family: 'Campton-medium';
    transform: scale(0);
    transition: 0.5s;
}
.sale-btn:hover button {
    transform: scale(1);
    transition: 0.5s;
}