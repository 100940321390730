/*--car-rental*/
.com-services ul li a {
    font-size: 32px;
    color: #a9a9a9de !important;
    font-weight: 500;
    text-decoration: none;
    list-style: none;
    background: transparent !important;
    outline: none;
    padding: 0;
  }
  .com-services ul {
    display: flex;
    justify-content: center;
    padding: 0;
    padding-bottom: 65px;
  }
  .com-services ul li a.active {
    color: #000000de !important;
    position: relative;
  }
  .com-services ul li {
    list-style: none;
    position: relative;
    border-left: 1px solid #7e7e7ea6;
    padding: 0 34px;
    line-height: 1;
  }
  .com-services ul li:first-child {
    border-left: 0px solid #7e7e7ea6;
  }
  .com-services {
    padding-top: 130px;
  }
  .com-services ul li a.active::before {
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 4px;
    width: 80px;
    content: "";
    border-radius: 30px;
    background: #FDC886;
  }
  .iner-rental img {
    width: 100%;
  }
  .iner-rental h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 14px 0 12px;
  }
  .inter-btn {
    display: flex;
    align-items: center;
  }
  .inter-btn p {
    max-width: 200px;
    margin: 0;
  }
  .inter-btn button.common-btn {
    margin-left: auto;
    margin-right: inherit;
    padding: 12px 30px;
    font-size: 16px;
  }
  .rent-img span {
    font-size: 14px;
    background: #fff;
    position: absolute;
    right: 17px;
    bottom: 17px;
    border-radius: 4px;
    padding: 9px 13px;
  }
  .inter-btn button.common-btn i, .inner-service button.common-btn {
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  .rent-img {
    position: relative;
  }
  .rent-img span i {
    margin-right: 9px;
  }
  .rental-cat .col-md-4 {
    margin-bottom: 64px;
  }
  .explore-more {
    width: 100%;
    text-align: center;
    display: table;
    padding-top: 30px;
  }
  .explore-more button {
    margin: 0 auto;
    display: table;
    float: none;
    padding: 12px 70px;
    font-size: 16px;
  }
  /* /* .com-services { 
    background-image: url(assets/images/bk-one.png);
  }
     */
  