.product-slider-main {
    display: flex;
}
.product-slider-main .main-sider {
    width: 76%;
    float: left;
    padding-right: 20px;
}
.product-slider-main .side-sider {
    width: 24%;
    float: left;
    position: relative;
}
.product-slider-main .main-sider img {
    width: 100%;
    height: 581px;
    border-radius: 0px;
}
h5.amp-loc {
    color: #4E4439;
    font-size: 18px;
    font-weight: 300;
    margin: 8px 0 19px;
}
.slider-desc h4 label {
    font-family: 'Campton-medium';
}
.slider-desc h4 label span {
    font-family: 'Campton-light';
    font-size: 24px;
}
.product-slider-main .side-sider img {
    width: 100%;
    height: 166px;
    object-fit: cover;
    border-radius: 0;
    margin-bottom: 33px;
}
.product-slider-main .side-sider .slick-track {
    display: flex;
    flex-direction: column !important;
}
.product-slider-main .side-sider .slick-track div {
    width: 100% !important;
}
.product-slider-main div {
    outline: none !important;
}
.more-hotos {
    position: absolute;
    bottom: 42px;
    height: 166px;
    background: #ffffffb3;
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.more-hotos p {
    margin: 0;
    font-size: 17px;
    color: #000;
}
.product-detail-page {
    padding-top: 30px;
}
.inn-th-slide .slick-track .slick-slide {
    padding: 0 12px;
}
.slider-desc h4 {
    font-size: 32px;
    margin: 14px 0 4px;
}
ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
ul.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4E4439;
    font-size: 16px;
    margin-bottom: 0px;
    position: relative;
}
h5.amp-loc img {
    margin-right: 13px;
}
ul.amnites li:first-child::before {
    display: none;
}
ul.amnites-faeture li span {
    background: #EFE1D0;
    font-size: 16px;
    color: #0000008a;
    font-weight: 400;
    padding: 9px 20px;
}
ul.amnites-faeture li {
    list-style: none;
    margin-right: 20px;
}
ul.amnites-faeture {
    padding: 0;
    display: flex;
    margin-bottom: 47px;
}
.slider-desc h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.map img {
    width: 100%;
    margin-bottom: 9px;
}
ul.amnites.aminity-wh-img li::before {
    display: none;
}
ul.amnites.aminity-wh-img {
    margin-bottom: 30px;
}
ul.amnites.aminity-wh-img li {
    margin-right: 21px;
}
ul.amnites li::before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4E4439;
}
ul.amnites li img {
    margin-right: 10px;
    max-width: 25px;
}
ul.list-gyr {
    padding: 0;
    margin-bottom: 41px;
}
ul.list-gyr li {
    font-size: 16px;
    color: #00000091;
    margin-bottom: 5px;
    list-style: none;
}
.you-like-slider {
    margin-top: 36px;
    margin-bottom: 130px;
    border-top: 1px solid #ddd;
    padding-top: 30px;
}
.you-like-slider .slick-list {
    padding-bottom: 0px;
    min-width: 103%;
}
.you-like-slider .iner-rental p {
    margin-bottom: 0;
    font-size: 13px;
    font-family: 'Campton-medium';
    max-width: 100%;
}
.you-like-slider h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: 'Campton-medium';
}
.slider-desc p {
    margin-bottom: 30px;
}
.you-like-slider button.slick-arrow.slick-next {
    position: absolute !important;
    top: inherit;
    background: #fff;
    bottom: -10px;
    right: -38px;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
    display:none !important;
}
/* .you-like-slider button.slick-arrow.slick-next::before {
    color: #989898;
    content: "\2303";
    font-size: 32px;
    transform: rotate(90deg);
    left: -3px;
    position: relative;
}

.you-like-slider button.slick-arrow.slick-prev::before {
    color: #989898;
    content: "\2303";
    font-size: 32px;
    transform: rotate(-90deg);
    left: 2px;
    position: relative;
} */
.you-like-slider button.slick-arrow.slick-prev {
    position: absolute !important;
    top: inherit;
    background: #fff;
    bottom: -10px;
    right: 22px;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
    left: inherit !important;
    display:none !important;
}
.you-like-slider .slick-list .slick-slide {
    position: relative;
    left: 19px;
}
.you-like-slider h3 {
    font-family: 'Glitten';
    font-size: 50px;
}
.product-slider-main button {
    display: none !important;
}