/*--banner-css--*/
.inner-banner-left {
    box-shadow: none;
    min-width: 698px;
    position: relative;
    z-index: 9;
    background: #ffffffe8;
    border-radius: 5px;
    padding: 17px 24px 40px;
    min-height: 423px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 1px #dddddd5e;
    
  }
  .inner-banner-left p {
    margin: 32px 0 37px;
  }
  
  h2 {
    margin-bottom: 72px;
    position: relative;
    padding-bottom: 9px;
  }
  .head-line {
    text-align: center;
  }
  .head-line h2::before
  {
    position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 80px;
      content: "";
      border-radius: 30px;
      background: #FDC886;
  }
  .head-line h2 {
    margin: 0 auto;
    display: table;
  }
  
  .inner-banner-right img {
    width: 100%;
  }
  .banner-page img {
    width: 100%;
}
.select-property {
  position: absolute;
  bottom: 152px;
  min-height: 162px;
  max-width: 1550px;
  width: 100%;
}
.select-property ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.select-property ul li {
  list-style: none;
}
.select-property ul li a {
  min-width: 170px;
  display: flex;
  padding: 16px 0;
  background: #fff;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #000000d6;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
}
.select-property ul li.active a 
{
  opacity: 1;
}
.page-full-data {
  margin-top: -93px;
}
