.car-services.property {
    padding-top: 126px;
}
.car-details {
    border-top: 1px solid #c9c9c996;
    padding-top: 14px;
    display: flex;
}
.car-details div {
    width: 33.33%;
    position: relative;
}
.car-details div::before {
    content: "";
    position: absolute;
    left: -30px;
    width: 1px;
    height: 40px;
    background: #c9c9c996;
    top: 3px;
}
.car-details div:first-child::before {
    display: none;
}
.car-details div span {
    font-weight: 500;
    color: #a0a0a0;
    font-family: 'Raleway', sans-serif;
}
.car-details div h6 {
    font-weight: 500;
    color: #383838;
    padding-top: 4px;
    font-family: 'Raleway', sans-serif;
}