.our-customers {
    background: linear-gradient(110deg, #FFE0BA 0%, rgba(217, 217, 217, 0) 100%);
    padding: 85px 0;
    position: relative;
}
.our-customers::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    background: white;
    height: 100%;
    content: "";
}
.our-customers .row {
    align-items: flex-end;
}
.our-customers button.slick-arrow {
    display: none !important;
}
.our-customer-left {
    padding-left: 160px;
    position: relative;
    left: 20px;
}
.our-customer-rigt {
    padding-left: 50px;
}
.our-customer-rigt .inner-propery {
    padding-left: 0;
    background: #fff;
    border: 1px solid #ddd;
}
.padin {
    padding-left: 30px;
}
.our-customer-rigt .inner-propery p {
    padding: 12px 16px;
}
.our-customer-rigt .inner-propery img {
    height: 226px;
}
.our-customer-rigt .inner-propery span {
    font-size: 16px;
    padding: 0px 0px 0px 12px;
    margin-bottom: 20px;
    width: 100%;
    display: table;
}
.our-customers .row {
    margin: 0;
}
.our-customers .row .col-md-8 {
    padding-right: 0;
}

