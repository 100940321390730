.our-app .inner-play {
    position: relative;
    text-align: center;
    padding-bottom: 140px;
}
.our-app {
    padding-top: 70px;
}
.our-app h3 {
    font-size: 40px;
    font-weight: 400;
    font-family: 'Glitten';
    margin-bottom: 34px;
}
.our-app p {
    font-size: 21px;
    font-weight: 400;
    color: #21323C;
    max-width: 660px;
    margin: 0px auto 69px;
    line-height: 25px;
    font-family: 'Campton-light';
    opacity: 60%;
}
img.frame-one {
    position: absolute;
    bottom: 0;
    left: 100px;
}
img.frame-two {
    position: absolute;
    right: 60px;
    bottom: 70px;
}
.our-app {
    background-image: url(../../assets/images/line.png);
    background-repeat: no-repeat;
    background-position: top 10px center;
    padding-bottom: 90px;
}