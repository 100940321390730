
/*--global-css--*/
body
{
  font-family: 'Campton-medium' !important;

  font-weight: 400;
  font-style: normal;
  background: #fff;
  /* background-image: url(assets/images/icbackground.png); */
  color: #21323C;
}

h2 {
  font-size: 32px;
  font-weight: 500;
  color: #1A1A1A;
  line-height: 41px;
  position: relative;
}
*
{
  outline: none;
}


.common-btn {
  background: #E89732;
  border: 0;
  width: auto;
  margin-right: auto;
  padding: 12px 72px;
  border-radius: 0px;
  font-size: 21px;
  text-decoration: none;
  outline: none;
  /* font-family: 'Circular Std'; */
  color: #fff;
  position: relative;
  font-size:16px;
  font-weight: 500;
}
.pagination ul {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 40px;
}
.pagination ul li a.active {
  color: #000;
}
.filter span {
  margin-right: 11px;
}
.modal-dialog {
  max-width: 50% !important;
}
.pagination ul li {
  list-style: none;
  position: relative;
  margin-right: 22px;
}
.list-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.filter select {
  border: 0;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  font-size: 16px;
  color: #000000;
}
.pagination ul li::before {
  content: "/";
  position: absolute;
  left: -15px;
  top: 3px;
}
.pagination ul li:first-child::before {
  display: none;
}
.pagination ul li a {
  color: #454545;
  font-size: 18px;
  text-decoration: none;
}
.common-btn::after {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0px;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 0.5s;
}
div#exampleModal {
  background: #000000a8;
}
.common-btn span {
  position: relative;
  z-index: 1;
}
.common-btn:hover {
  color: #fff;
  transition: 0.5s;
}
.common-btn:hover::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.5s;
}
.banner-page h1 {
  font-size: 105px;
  font-weight: 400;
  line-height: 105px;
  font-family: 'Glitten';
  color: #fff;
}
.container {
  max-width: 1550px !important;
}
p {
  font-size: 16px;
  line-height: 21px;
  color: #00000091;
  /* font-family: 'Circular Std'; */
}
.banner-page p
{
  color: #fff;
}


nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: transparent !important;
  padding: 0;
}


.sale-btn {
  position: relative;
}
.sale-btn a {
  background: #000000db;
  color: #fff;
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 21px;
  padding: 15px 0;
  font-family: 'Campton-medium';
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.sale-btn:hover a {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.sale-btn:hover a:hover{
  color: #fff;
}
@font-face {
    font-family: 'Campton-light';
    src: url(/static/media/Campton-Light.8da94fe2.eot);
    src: url(/static/media/Campton-Light.8da94fe2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Light.4747330c.woff2) format('woff2'),
        url(/static/media/Campton-Light.26b93865.woff) format('woff'),
        url(/static/media/Campton-Light.46149eeb.ttf) format('truetype'),
        url(/static/media/Campton-Light.58b5c0a1.svg#Campton-Light) format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-extralight';
    src: url(/static/media/Campton-ExtraLight.09b92596.eot);
    src: url(/static/media/Campton-ExtraLight.09b92596.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-ExtraLight.c2e6f784.woff2) format('woff2'),
        url(/static/media/Campton-ExtraLight.3f8387f9.woff) format('woff'),
        url(/static/media/Campton-ExtraLight.29cb62ae.ttf) format('truetype'),
        url(/static/media/Campton-ExtraLight.7b5fe6e9.svg#Campton-ExtraLight) format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-black';
    src: url(/static/media/Campton-Black.4100c472.eot);
    src: url(/static/media/Campton-Black.4100c472.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Black.c1bae319.woff2) format('woff2'),
        url(/static/media/Campton-Black.4c89998a.woff) format('woff'),
        url(/static/media/Campton-Black.daf4922b.ttf) format('truetype'),
        url(/static/media/Campton-Black.be1908f7.svg#Campton-Black) format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-Book';
    src: url(/static/media/Campton-Bold.c274bcc3.eot);
    src: url(/static/media/Campton-Bold.c274bcc3.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Bold.1d0d862c.woff2) format('woff2'),
        url(/static/media/Campton-Bold.141a4e74.woff) format('woff'),
        url(/static/media/Campton-Bold.0a2324ec.ttf) format('truetype'),
        url(/static/media/Campton-Bold.14940695.svg#Campton-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-normal';
    src: url(/static/media/Campton-Book.054bfdcd.eot);
    src: url(/static/media/Campton-Book.054bfdcd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Book.fc65d6f6.woff2) format('woff2'),
        url(/static/media/Campton-Book.68c0f7ee.woff) format('woff'),
        url(/static/media/Campton-Book.9b6cb7b9.ttf) format('truetype'),
        url(/static/media/Campton-Book.afe7856d.svg#Campton-Book) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton';
    src: url(/static/media/Campton-Thin.2d5d5921.eot);
    src: url(/static/media/Campton-Thin.2d5d5921.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Thin.87a51aa4.woff2) format('woff2'),
        url(/static/media/Campton-Thin.7f3f873d.woff) format('woff'),
        url(/static/media/Campton-Thin.b35ac3f2.ttf) format('truetype'),
        url(/static/media/Campton-Thin.6586c963.svg#Campton-Thin) format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-semibold';
    src: url(/static/media/Campton-SemiBold.db2d9222.eot);
    src: url(/static/media/Campton-SemiBold.db2d9222.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-SemiBold.f04aeaf5.woff2) format('woff2'),
        url(/static/media/Campton-SemiBold.c0a1b7e6.woff) format('woff'),
        url(/static/media/Campton-SemiBold.28ab1a4d.ttf) format('truetype'),
        url(/static/media/Campton-SemiBold.38a4a6ef.svg#Campton-SemiBold) format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-medium';
    src: url(/static/media/Campton-Medium.5b4688ac.eot);
    src: url(/static/media/Campton-Medium.5b4688ac.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-Medium.a32e85af.woff2) format('woff2'),
        url(/static/media/Campton-Medium.d90ddc81.woff) format('woff'),
        url(/static/media/Campton-Medium.b17a3cd4.ttf) format('truetype'),
        url(/static/media/Campton-Medium.60cd045d.svg#Campton-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Campton-extra-bold';
    src: url(/static/media/Campton-ExtraBold.a2c5e0d6.eot);
    src: url(/static/media/Campton-ExtraBold.a2c5e0d6.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Campton-ExtraBold.842c6f20.woff2) format('woff2'),
        url(/static/media/Campton-ExtraBold.d295172c.woff) format('woff'),
        url(/static/media/Campton-ExtraBold.77538e6b.ttf) format('truetype'),
        url(/static/media/Campton-ExtraBold.5c4b9049.svg#Campton-ExtraBold) format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Glitten';
    src: url(/static/media/GlittenRegular.4974b593.eot);
    src: url(/static/media/GlittenRegular.4974b593.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GlittenRegular.77587e36.woff2) format('woff2'),
        url(/static/media/GlittenRegular.20384e88.woff) format('woff'),
        url(/static/media/GlittenRegular.d663a489.ttf) format('truetype'),
        url(/static/media/GlittenRegular.cb9c396a.svg#GlittenRegular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.headerpage {
    padding: 25px 0;
    z-index: 9;
    width: 100%;
    position: relative;
}
.headerpage ul.navbar-nav.me-auto {
    margin: 0 auto;
}
.headerpage ul.navbar-nav.me-auto li a {
    font-size: 18px;
    text-decoration: none;
    margin: 0 20px;
    color: #000;
    position: relative;
}
a.navbar-brand {
    color: #000;
}
.log-btn button {
    font-size: 18px;
    border: 0;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddddddbd;
    padding: 8px 31px;
}
.headerpage ul.navbar-nav.me-auto li a::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 0%;
    transition: 0.5s;
    height: 3px;
    background: #E89732;
    border-radius: 10px;
}
.headerpage ul.navbar-nav.me-auto li a:hover::before {
    width: 100%;
    transition: 0.5s;
}
.banner-page img {
    width: 100%;
}

.banner-page {
    position: relative;
    min-height: 860px;
}
.banner-data {
    position: absolute;
    top: 162px;
    /* transform: translateY(-50%); */
}

.banner-data p {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-style: italic;
    margin: 19px 0 0;
}
.banner-page {
    background-image: url(/static/media/banner.4f09bf9e.png);
    background-repeat: no-repeat;
    background-position: top center;
}
.top-property {
    margin-top: -80px;
}
/*--banner-css--*/
.inner-banner-left {
    box-shadow: none;
    min-width: 698px;
    position: relative;
    z-index: 9;
    background: #ffffffe8;
    border-radius: 5px;
    padding: 17px 24px 40px;
    min-height: 423px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 1px #dddddd5e;
    
  }
  .inner-banner-left p {
    margin: 32px 0 37px;
  }
  
  h2 {
    margin-bottom: 72px;
    position: relative;
    padding-bottom: 9px;
  }
  .head-line {
    text-align: center;
  }
  .head-line h2::before
  {
    position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 80px;
      content: "";
      border-radius: 30px;
      background: #FDC886;
  }
  .head-line h2 {
    margin: 0 auto;
    display: table;
  }
  
  .inner-banner-right img {
    width: 100%;
  }
  .banner-page img {
    width: 100%;
}
.select-property {
  position: absolute;
  bottom: 152px;
  min-height: 162px;
  max-width: 1550px;
  width: 100%;
}
.select-property ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.select-property ul li {
  list-style: none;
}
.select-property ul li a {
  min-width: 170px;
  display: flex;
  padding: 16px 0;
  background: #fff;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  color: #000000d6;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
}
.select-property ul li.active a 
{
  opacity: 1;
}
.page-full-data {
  margin-top: -93px;
}

/*---services--*/
.services {
  padding-top: 80px;
}
  .inner-service {
    display: flex;
    max-width: 620px;
    align-items: center;
  }
  
  .left-service {
    width: 50%;
    padding-right: 12px;
  }
  .right-service {
    width: 50%;
    padding-left:32px;
  }
  .inner-service button.common-btn {
    padding: 12px 30px;
    font-size: 16px;
  }
  .left-service img {
    width: 100%;
    height: 315px;
    object-fit: cover;
    border-radius: 10px;
  }
  .right-service h3 {
    font-size: 22px;
    color: #000c;
    font-weight: 600;
    margin-bottom: 28px;
  }
  .right-service p {
    margin-bottom: 30px;
  }
  .services .head-line {
    padding-bottom: 60px;
  }
  .services-right {
    display: flex;
}
.services-right .inner-ser {
  width: 33.33%;
  float: left;
}
.services-right .inner-ser p {
  font-size: 18px;
  padding-right: 70px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  color: #000;
  line-height: 22px;
  min-height: 161px;
}
.inner-ser h4 {
  font-size: 21px;
  font-weight: 500;
  margin-top: 21px;
  margin-bottom: 14px;
  font-family: 'Raleway', sans-serif;
}
.services-right {
  display: flex;
  border: 1px solid transparent;
  border-image: linear-gradient(53deg, #dedede , white);
  border-image-slice: 1;
  padding: 30px 40px;
}
h2 {
  font-family: 'Glitten';
  font-size: 80px;
  line-height: 80px;
  margin: 0;
}
.services-left p {
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  color: #21323C;
  font-family: 'Raleway', sans-serif;
}
.services .row {
  align-items: flex-end;
}
.services-right .inner-ser a {
  text-decoration: none;
  font-size: 18px;
  color: #E89732;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}
.services-right .inner-ser a i {
  margin-left: 7px;
  transition: 1s;
}
.services-right .inner-ser a:hover i {
  transition: 1s;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
 
}
.services-right .inner-ser a i {
  transition: 1s;
}
.services-right .inner-ser a, .services-right .inner-ser a:hover {
  transition: 1s;
}
.services {
  background-image: url(/static/media/lines.247d9cb7.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 310px;
}
.services-left h2 {
  position: relative;
}
.services-left h2::before {
  content: "";
  position: absolute;
  /* background-image: url(../../assets/images/str.png); */
  width: 16px;
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    top: -36px;
}
/*--car-rental*/
.com-services ul li a {
    font-size: 32px;
    color: #a9a9a9de !important;
    font-weight: 500;
    text-decoration: none;
    list-style: none;
    background: transparent !important;
    outline: none;
    padding: 0;
  }
  .com-services ul {
    display: flex;
    justify-content: center;
    padding: 0;
    padding-bottom: 65px;
  }
  .com-services ul li a.active {
    color: #000000de !important;
    position: relative;
  }
  .com-services ul li {
    list-style: none;
    position: relative;
    border-left: 1px solid #7e7e7ea6;
    padding: 0 34px;
    line-height: 1;
  }
  .com-services ul li:first-child {
    border-left: 0px solid #7e7e7ea6;
  }
  .com-services {
    padding-top: 130px;
  }
  .com-services ul li a.active::before {
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 4px;
    width: 80px;
    content: "";
    border-radius: 30px;
    background: #FDC886;
  }
  .iner-rental img {
    width: 100%;
  }
  .iner-rental h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 14px 0 12px;
  }
  .inter-btn {
    display: flex;
    align-items: center;
  }
  .inter-btn p {
    max-width: 200px;
    margin: 0;
  }
  .inter-btn button.common-btn {
    margin-left: auto;
    margin-right: inherit;
    padding: 12px 30px;
    font-size: 16px;
  }
  .rent-img span {
    font-size: 14px;
    background: #fff;
    position: absolute;
    right: 17px;
    bottom: 17px;
    border-radius: 4px;
    padding: 9px 13px;
  }
  .inter-btn button.common-btn i, .inner-service button.common-btn {
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  .rent-img {
    position: relative;
  }
  .rent-img span i {
    margin-right: 9px;
  }
  .rental-cat .col-md-4 {
    margin-bottom: 64px;
  }
  .explore-more {
    width: 100%;
    text-align: center;
    display: table;
    padding-top: 30px;
  }
  .explore-more button {
    margin: 0 auto;
    display: table;
    float: none;
    padding: 12px 70px;
    font-size: 16px;
  }
  /* /* .com-services { 
    background-image: url(assets/images/bk-one.png);
  }
     */
  



   /*--form-css--*/
   .contact-from {
    padding-top: 100px;
    padding-bottom: 100px;
}
.contact-from form {
  max-width: 1050px;
  margin: 0 auto;
  box-shadow: 0 0 10px 1px #ddd;
  border-radius: 10px;
  padding: 50px;
}
.contact-from form .form-group label {
  width: 100%;
  font-size: 18px;
  margin-bottom: 3px;
}
.contact-from form .form-group input {
  width: 100%;
  border: 0;
  height: 48px;
  background: #F4F4F4;
  border-radius: 3px;
  padding: 0 15px;
  outline: none;
}
.contact-from form .form-group textarea {
  height: 153px;
  width: 100%;
  resize: none;
  background: #F4F4F4;
  border: 0;
  border-radius: 3px;
  padding: 14px 15px;
  outline: none;
}
.contact-from .head-line {
  padding-bottom: 50px;
}
.contact-from form .form-group input[type="submit"] {
  background: #FDC886;
  margin: 0 auto;
  display: table;
  width: auto;
  padding: 0px 70px;
  border: 0;
  border-radius: 3px;
}


.seacrh-bn .seacrhtime .srchtab img {
    width: auto;
}
.seacrh-bn {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 30px 40px;
}
.seacrhtime {
    display: flex;
    align-items: center;
    width: 85%;
}
.srch-btn {
    width: 15%;
    text-align: right;
}
.seacrhtime .srchtab {
    width: 33.33%;
    font-family: 'Raleway', sans-serif;
    position: relative;
}
.seacrhtime .srchtab::before {
    content: "";
    width: 1px;
    height: 50px;
    background: #ababab8c;
    position: absolute;
    left: -112px;
    top: 6px;
}
.seacrhtime .srchtab:first-child::before {
    display: none;
}
.seacrhtime .srchtab label {
    width: 100%;
    font-size: 14px;
    color: #000;
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 7px;
}
.seacrhtime .srchtab span {
    font-size: 18px;
    color: #000;
    opacity: 0.8;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}
.seacrhtime .srchtab span img {
    margin-right: 10px;
}
.srch-btn button {
    background: #E89732;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    line-height: 23px;
    border: 0;
    padding: 18px 54px;
}
.avail-section {
    padding: 120px 0;
}
.avail-section .container {
    max-width: 1060px !important;
}
.avail-left h4 {
    font-size: 40px;
    font-weight: 400;
    font-family: 'Glitten';
}
.avail-left p {
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
    font-weight: 500;
    color: #21323C;
    font-family: 'Raleway', sans-serif;
    padding-right: 100px;
    margin-bottom: 36px;
    margin-top: 14px;
}
.avail-section button.common-btn {
    background: #fff;
    color: #E89732;
    border: 1px solid #E89732;
}
.property h4 {
    text-align: center;
}
h4 {
    font-size: 40px;
    font-weight: 400;
  }
  .property p.tp-brd {
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    color: #21323C;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: -30px;
    padding-top: 46px;
    margin-bottom: 56px;
}

.property h4 {
    margin-bottom: 16px;
    display: table;
    position: relative;
    padding: 0;
    font-size: 26px;
    font-weight: 500;
}
.inner-propery img {
    width: 100%;
}
.property .col-md-4.center {
    padding: 0;
}
.property .col-md-4 {
    padding: 0 20px;
}
.inner-propery img {
    height: 290px;
    object-fit: cover;
}
.inner-propery h5 {
    color: #4E4439;
    font-size: 21px;
    margin-top: 18px;
    margin-bottom: 13px;
}
.property
{
    font-family: 'Raleway', sans-serif;
}
.inner-propery p {
    font-size: 14px;
    font-weight: 400;
    padding-right: 70px;
}
.inner-propery {
    margin-bottom: 56px;
}
.col-md-4.center .inner-propery img {
    height: 734px;
}
.view-all-btn button {
    margin: 0 auto;
    float: none;
}
.view-all-btn {
    width: 100%;
    text-align: center;
}
.sale-btn {
    position: relative;
}
.sale-btn button {
    background: #000000db;
    color: #fff;
    border: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 21px;
    padding: 15px 0;
    font-family: 'Campton-medium';
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: 0.5s;
}
.sale-btn:hover button {
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.5s;
}
.car-services.property {
    padding-top: 126px;
}
.car-details {
    border-top: 1px solid #c9c9c996;
    padding-top: 14px;
    display: flex;
}
.car-details div {
    width: 33.33%;
    position: relative;
}
.car-details div::before {
    content: "";
    position: absolute;
    left: -30px;
    width: 1px;
    height: 40px;
    background: #c9c9c996;
    top: 3px;
}
.car-details div:first-child::before {
    display: none;
}
.car-details div span {
    font-weight: 500;
    color: #a0a0a0;
    font-family: 'Raleway', sans-serif;
}
.car-details div h6 {
    font-weight: 500;
    color: #383838;
    padding-top: 4px;
    font-family: 'Raleway', sans-serif;
}
.tour-services.property {
    padding-top: 100px;
}
.tour-services.property .inner-propery img {
    height: 610px;
}
.blogs.property {
    padding-top: 100px;
    padding-bottom: 60px;
}
.blogs.property .inner-propery {
    padding: 0 20px;
}
.blogs.property .inner-propery p a {
    color: #e89732;
    text-decoration: none;
    font-weight: 500;
}
.blog-slider {
    max-width: 1400px;
    margin: 0 auto;
}
.blogs.property .inner-propery p {
    padding: 0;
}
.blogs.property .inner-propery img {
    height: 191px;
}
button.slick-arrow.slick-prev {
    left: -50px;
}
button.slick-arrow.slick-next
{
    right: -50px;
}
.blogs.property h4 {
    width: 100%;
}
button.slick-arrow.slick-prev::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #828282;
    display: flex;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
button.slick-arrow.slick-next::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #828282;
    display: flex;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.our-customers {
    background: linear-gradient(110deg, #FFE0BA 0%, rgba(217, 217, 217, 0) 100%);
    padding: 85px 0;
    position: relative;
}
.our-customers::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    background: white;
    height: 100%;
    content: "";
}
.our-customers .row {
    align-items: flex-end;
}
.our-customers button.slick-arrow {
    display: none !important;
}
.our-customer-left {
    padding-left: 160px;
    position: relative;
    left: 20px;
}
.our-customer-rigt {
    padding-left: 50px;
}
.our-customer-rigt .inner-propery {
    padding-left: 0;
    background: #fff;
    border: 1px solid #ddd;
}
.padin {
    padding-left: 30px;
}
.our-customer-rigt .inner-propery p {
    padding: 12px 16px;
}
.our-customer-rigt .inner-propery img {
    height: 226px;
}
.our-customer-rigt .inner-propery span {
    font-size: 16px;
    padding: 0px 0px 0px 12px;
    margin-bottom: 20px;
    width: 100%;
    display: table;
}
.our-customers .row {
    margin: 0;
}
.our-customers .row .col-md-8 {
    padding-right: 0;
}


.our-app .inner-play {
    position: relative;
    text-align: center;
    padding-bottom: 140px;
}
.our-app {
    padding-top: 70px;
}
.our-app h3 {
    font-size: 40px;
    font-weight: 400;
    font-family: 'Glitten';
    margin-bottom: 34px;
}
.our-app p {
    font-size: 21px;
    font-weight: 400;
    color: #21323C;
    max-width: 660px;
    margin: 0px auto 69px;
    line-height: 25px;
    font-family: 'Campton-light';
    opacity: 60%;
}
img.frame-one {
    position: absolute;
    bottom: 0;
    left: 100px;
}
img.frame-two {
    position: absolute;
    right: 60px;
    bottom: 70px;
}
.our-app {
    background-image: url(/static/media/line.3939dbd2.png);
    background-repeat: no-repeat;
    background-position: top 10px center;
    padding-bottom: 90px;
}
.expolre-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.explore-inner {
    width: 20%;
    padding: 0 15px;
}
h4.common-heading {
    margin-bottom: 16px;
    display: table;
    position: relative;
    padding: 0;
    font-size: 26px;
    font-weight: 500;
}
.explore-data {
    padding-top: 80px;
}
.product-slider-main {
    display: flex;
}
.product-slider-main .main-sider {
    width: 76%;
    float: left;
    padding-right: 20px;
}
.product-slider-main .side-sider {
    width: 24%;
    float: left;
    position: relative;
}
.product-slider-main .main-sider img {
    width: 100%;
    height: 581px;
    border-radius: 0px;
}
h5.amp-loc {
    color: #4E4439;
    font-size: 18px;
    font-weight: 300;
    margin: 8px 0 19px;
}
.slider-desc h4 label {
    font-family: 'Campton-medium';
}
.slider-desc h4 label span {
    font-family: 'Campton-light';
    font-size: 24px;
}
.product-slider-main .side-sider img {
    width: 100%;
    height: 166px;
    object-fit: cover;
    border-radius: 0;
    margin-bottom: 33px;
}
.product-slider-main .side-sider .slick-track {
    display: flex;
    flex-direction: column !important;
}
.product-slider-main .side-sider .slick-track div {
    width: 100% !important;
}
.product-slider-main div {
    outline: none !important;
}
.more-hotos {
    position: absolute;
    bottom: 42px;
    height: 166px;
    background: #ffffffb3;
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.more-hotos p {
    margin: 0;
    font-size: 17px;
    color: #000;
}
.product-detail-page {
    padding-top: 30px;
}
.inn-th-slide .slick-track .slick-slide {
    padding: 0 12px;
}
.slider-desc h4 {
    font-size: 32px;
    margin: 14px 0 4px;
}
ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
ul.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4E4439;
    font-size: 16px;
    margin-bottom: 0px;
    position: relative;
}
h5.amp-loc img {
    margin-right: 13px;
}
ul.amnites li:first-child::before {
    display: none;
}
ul.amnites-faeture li span {
    background: #EFE1D0;
    font-size: 16px;
    color: #0000008a;
    font-weight: 400;
    padding: 9px 20px;
}
ul.amnites-faeture li {
    list-style: none;
    margin-right: 20px;
}
ul.amnites-faeture {
    padding: 0;
    display: flex;
    margin-bottom: 47px;
}
.slider-desc h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.map img {
    width: 100%;
    margin-bottom: 9px;
}
ul.amnites.aminity-wh-img li::before {
    display: none;
}
ul.amnites.aminity-wh-img {
    margin-bottom: 30px;
}
ul.amnites.aminity-wh-img li {
    margin-right: 21px;
}
ul.amnites li::before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4E4439;
}
ul.amnites li img {
    margin-right: 10px;
    max-width: 25px;
}
ul.list-gyr {
    padding: 0;
    margin-bottom: 41px;
}
ul.list-gyr li {
    font-size: 16px;
    color: #00000091;
    margin-bottom: 5px;
    list-style: none;
}
.you-like-slider {
    margin-top: 36px;
    margin-bottom: 130px;
    border-top: 1px solid #ddd;
    padding-top: 30px;
}
.you-like-slider .slick-list {
    padding-bottom: 0px;
    min-width: 103%;
}
.you-like-slider .iner-rental p {
    margin-bottom: 0;
    font-size: 13px;
    font-family: 'Campton-medium';
    max-width: 100%;
}
.you-like-slider h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-family: 'Campton-medium';
}
.slider-desc p {
    margin-bottom: 30px;
}
.you-like-slider button.slick-arrow.slick-next {
    position: absolute !important;
    top: inherit;
    background: #fff;
    bottom: -10px;
    right: -38px;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
    display:none !important;
}
/* .you-like-slider button.slick-arrow.slick-next::before {
    color: #989898;
    content: "\2303";
    font-size: 32px;
    transform: rotate(90deg);
    left: -3px;
    position: relative;
}

.you-like-slider button.slick-arrow.slick-prev::before {
    color: #989898;
    content: "\2303";
    font-size: 32px;
    transform: rotate(-90deg);
    left: 2px;
    position: relative;
} */
.you-like-slider button.slick-arrow.slick-prev {
    position: absolute !important;
    top: inherit;
    background: #fff;
    bottom: -10px;
    right: 22px;
    width: 40px;
    height: 40px;
    display: flex !important;
    z-index: 99;
    border-radius: 100px;
    border: 1px solid #979797;
    align-items: center;
    justify-content: center;
    left: inherit !important;
    display:none !important;
}
.you-like-slider .slick-list .slick-slide {
    position: relative;
    left: 19px;
}
.you-like-slider h3 {
    font-family: 'Glitten';
    font-size: 50px;
}
.product-slider-main button {
    display: none !important;
}
.price-table {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 11px;
    padding: 27px;
    box-shadow: 0 0 10px 1px #ddd;
}
.price-head {
    display: flex;
    align-items: center;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    justify-content: space-between;
}
.price-head h6 {
    font-size: 16px;
    color: #E89732;
}
.price-table .form-group label {
    font-size: 16px;
    color: #131415cc;
    margin-bottom: 5px;
    width: 100%;
}
input#number {
    max-width: 70px;
    text-align: center;
}
.counter span {
    width: 30px;
    height: 30px;
    display: flex;
    border: 1px solid #a2a2a2;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}
.price-table .form-group .counter input {
    height: 30px;
    border: 0;
    color: #000;
    appearance: none;
    -webkit-appearance: none;
    -webkit-appearance: none;
    padding-left: 26px;
}
.counter {
    display: flex;
    align-items: center;
}
.price-table .form-group input {
    height: 48px;
    border: 1px solid #E89732;
    width: 100%;
    border-radius: 0;
    padding: 0 12px;
    color: #909090;
    outline: none;
}
.price-table .form-group {
    margin-bottom: 18px;
}
.price-table .form-group input[type="submit"] {
    background: #f6bc8d;
    color: #000;
    border: 0;
}
.form-group.submit {
    border-top: 0;
    padding-top: 3px;
    width: 100%;
}
.data-frm {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}
.form-group.submit button {
    background: #E89732;
    border: 0;
    height: 48px;
    color: #fff;
    width: 100%;
    padding-top: 2px;
}
.page-modal h4 {
    font-family: 'Glitten';
    font-size: 40px;
    color: #4E4439;
    margin-bottom: 35px;
}
.page-modal form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.page-modal {
    padding: 26px 40px;
}
.error {
    font-size: 14px;
    color: red;
}
.modal-header {
    padding: 0;
    border: 0;
    position: absolute;
    right: 50px;
    top: 42px;
    border: 2px solid #00000063;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    z-index: 9;
}
.modal-header button {
    padding: 0 !important;
    margin: 0 !IMPORTANT;
}
.page-modal button {
    font-size: 14px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    background: #E89732;
    border: 0;
    color: #fff;
    padding: 12px 0;
    width: 100%;
    clear: both;
}
.page-modal form .from-group {
    width: 47%;
    margin-bottom: 40px;
}
.page-modal form .from-group label {
    font-size: 14px;
    color: #00000094;
    width: 100%;
    font-weight: 400;
}
.page-modal form .from-group input {
    height: 48px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #00000017;
    background: #F1F1F166;
    padding: 0 14px;
    color: #949494;
    font-weight: 400;
    font-size: 15px;
}
/*--footer--*/
.footer {
  background: #21323C;
  padding: 80px 100px 17px;
}
  .footer-left ul {
    padding: 0;
    margin: 0;
  }
  .footer-left ul li {
    font-size: 14px;
    list-style: none;
    color: #0000007d;
    margin-bottom: 9px;
  }
  .footer-left ul li i {
    min-width: 24px;
    color: #000;
  }
  .footer-left {
    padding-right: 60px;
  }
  .footer-menu h5 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 17px;
    opacity: 0.5;
}
  .footer-menu ul {
    padding: 0;
    margin: 0;
  }
  img.ft-img-four {
    position: absolute;
    right: 0;
    bottom: 0;
}
  img.ft-img-three {
    position: absolute;
    left: 140px;
    bottom: 0;
}
  .register-property {
    position: relative;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 100px;
    margin-bottom: 60px;
}
.register-property h2 {
  font-size: 70px;
  line-height: 77px;
  margin-bottom: 21px;
  color: #fff;
}
.register-property p {
  font-size: 21px;
  line-height: 25px;
  max-width: 670px;
  margin: 0 auto 40px;
  color: #fff;
  opacity: 0.6;
  font-family: 'Campton-light';
}
  .footer-menu ul li {
    list-style: none;
    margin-bottom: 10px;
  }
  
  .footer-menu ul li a {
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Campton-light';
    opacity: 0.5;
  }
  .footer-menu ul i {
    color: #000;
  } 
  .footer-menu.social ul {
    display: flex;
  }
  .footer-menu.social ul li {
    margin-right: 17px;
  }
  .footer-menu.social ul li i {
    font-size: 19px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100px;
}
  img.ft-img-one {
    position: absolute;
    top: 0;
    left: 0;
}
img.ft-img-two {
  position: absolute;
  top: 0;
  right: 200px;
}
.fotter-menu {
  border-top: 1px solid #dddddd4d;
  padding-top: 53px;
  padding-bottom: 30px;
}
.fotter-menu p {
  color: #fff;
  font-weight: 400;
  opacity: 0.5;
  font-family: 'Campton-light';
}
.footer-left a {
  font-size: 45px;
  color: #fff;
  opacity: 0.5;
  padding-bottom: 28px;
  display: table;
  font-family: 'Campton-light';
}
.lower-fr {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dddddd4d;
  padding-top: 21px;
}
.lower-fr p {
  color: #fff;
  font-size: 16px;
  opacity: 0.5;
  font-family: 'Campton-light';
  margin: 0;
}
.lower-fr ul {
  padding: 0;
  margin: 0;
  display: flex;
  padding-left: 40px;
}
.lower-fr ul li {
  margin-right: 38px;
  list-style: none;
}
.lower-fr ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  opacity: 0.5;
  font-family: 'Campton-light';
}
.register-property a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
}
.register-property a i {
  margin-left: 11px;
  font-size: 16px;
}
.filter {
    position: relative;
}
.filter-main {
    position: absolute;
    padding: 24px;
    background: #fff;
    z-index: 1;
    min-width: 390px;
    right: 0;
    top: 150px;
    box-shadow: 0 0 10px 1px #ddd;
    opacity: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: 0.5s;
}

.filter.active .filter-main {
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    top: 43px;
}
.list-filter button.filter-btn {
    font-size: 15px;
    width: 100%;
    text-align: center;
    color: #E89732;
    border: 1px solid #E89732;
    padding: 11px 0;
}
span.range-slider__wrap {
    margin-bottom: 16px;
}
.filter-list ul li {
    background: #F9F9F9;
    border: 1px solid #BDC2C5;
    padding: 7px 11px;
    text-align: center;
    list-style: none;
    margin-right: 15px;
    font-size: 12px;
    color: #00000094;
    margin-bottom: 14px;
}
.filter-list ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.list-filter button span {
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.filter-list h6 {
    font-size: 14px;
    font-weight: 700;
}
.list-filter button {
    background: #fff;
    border: 0;
    padding: 0;
}
.filter-main::before {
    position: absolute;
    top: -14px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAOCAYAAADez2d9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABnSURBVHgBvdTBDYAgEETRsRNLsBQ7sBU61Q4sYZWETcSEwMIsP5nzuw3QkYiEOHiXIC3Aqx/kBxYg7QCrCsQDG6Fx0AhpO6x1QrH73TYDsoEEqA0kQl9wnQFpZwY6Qhm4JJX3AOWuB4gEyZt14bZuAAAAAElFTkSuQmCC);
    width: 32px;
    height: 26px;
    right: 20px;
    background-repeat: no-repeat;
    content: "";
    background-size: 100%;
}
@media (max-width:767px) {
    .select-property {
        display: none;
    }
    .banner-page h1 {
        font-size: 29px;
        line-height: 56px;
        color: #000;
    }
    .banner-page h1 br {
        display: none;
    }
    .footer {
        overflow: hidden;
    }
    button.slick-arrow {
        display: none !important;
    }
    .container-fluid {
        padding: 0;
    }
    .headerpage .log-btn button {
        font-size: 15px;
        padding: 6px 7px;
        border-radius: 3px;
    }
    .headerpage button.navbar-toggler {
        background: #fff;
        padding: 2px 4px;
        border: 0;
        box-shadow: none;
        outline: none;
        position: absolute;
        right: 0;

    }
    .mbile-slider .slick-track .slick-slide, .mobile-car .slick-track .slick-slide, .mob-tour .slick-track .slick-slide, .blog-slider .slick-track .slick-slide {
        padding: 0 8px;
    }
    .inner-propery p {
        font-size: 13px;
        font-weight: 400;
        padding-right: 0;
        line-height: 19px;
    }
    .mob-tour .inner-ser h4 {
        font-size: 15px;
        margin-top: 12px;
        margin-bottom: 5px;
    }
    .mob-tour .inner-ser a {
        color: #e89732;
        text-decoration: none;
        font-size: 14px;
    }
    .inner-propery {
        margin-bottom: 0;
    }
    .inner-propery h5 {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .mob-tour h4 {
        margin-bottom: 20px;
    }
    .mob-tour {
        padding-bottom: 16px;
    }
    .inner-ser p {
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 6px;
        min-height: 71px;
    }
    .inner-propery img {
        height: 170px;
    }
    .log-btn {
        padding-right: 54px;
    }
    body .banner-page {
        min-height: 390px;
        background-size: cover;
    }
    .banner-data p {
        display: none;
    }
    .banner-data {
        top: 212px;
    }
    div#navbarSupportedContent {
        position: absolute;
        top: 43px;
        left: 0;
        right: 0;
        background: #fff;
        padding: 13px 0 6px 1px;
    }
    .headerpage ul.navbar-nav.me-auto li a {

        color: #5a5a5a !important;
    }
    .mob-tour {
        padding-top: 18px;
    }
    .blogs.property {
        padding-top: 0px;
        padding-bottom: 25px;
    }
   body h4 {
        font-size: 17px;
    }
    .mbile-slider .slick-track, .explore-data-mobile .slick-track, .mobile-car .slick-track, .mob-tour .slick-track, .blog-slider .slick-track  {
        position: relative;
        left: 13px;
    }
    .explore-inner {
        padding: 0;
    }
    .tour-services.property {
        padding-top: 15px;
    }
    .property h4 {
        font-size: 17px;
        font-weight: 600;
        text-align: left;
    }
    .explore-data-mobile .slick-track .slick-slide {
        padding: 0 8px;
    }
    h4.common-heading {
        margin-bottom: 9px;
        font-size: 17px;
        margin-top: 21px;
    }
    .explore-data-mobile {
        margin-bottom: 20px;
    }
    .top-property-mob {
        margin-top: -79px;
    }
    .services {
        padding-top: 30px;
    }
    h2 {
        font-size: 29px;
        line-height: 33px;
    }
    .services-left p {
        font-size: 16px;
    }
    br
    {
        display: none;
    }
    .services-right {
        padding: 15px 0 0;
        border: 0;
    }
    .services-right .inner-ser {
        width: 100%;
    }
    .services-right {
        flex-wrap: wrap;
        text-align: center;
    }
    .services-right .inner-ser p {
        font-size: 15px;
        padding-right: 0;
        line-height: 22px;
    }
    .services-right .inner-ser a {
        font-size: 16px;
    }
    .inner-ser h4 {
        font-size: 18px;
    }
    .services-right .inner-ser {
        margin-bottom: 34px;
    
    }
    .car-services.property {
        padding-top: 10px;
    }
    .our-app {
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .our-app h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .our-app p {
        font-size: 16px;
        margin: 0px auto 16px;
        line-height: 21px;
    }
    img.frame-one, img.frame-two {
        display: none;
    }
    .playstore {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .our-app .inner-play {
        padding-bottom: 0;
    }
    .register-property img {
        display: none;
    }
    .register-property {
        padding-bottom: 30px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .register-property h2 {
        font-size: 29px;
        line-height: 34px;
        margin-bottom: 2px;
    }
    .register-property p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto 18px;
    }
    .footer {
        padding: 40px 0px 17px;
    }
    .register-property a {
        font-size: 16px;
    }
    .fotter-menu {
        padding-top: 14px;
        padding-bottom: 10px;
    }
    .footer-left a {
        font-size: 28px;
        padding-bottom: 8px;
    }
    .playstore img {
        max-width: 130px;
    }
    .footer-left {
        padding-right: 0;
    }
    .footer-menu h5 {
        font-size: 19px;
        margin-bottom: 9px;
        margin-top: 15px;
    }
    .footer-menu.social {
        padding-top: 13px;
    }

    .lower-fr ul li {
        margin-right: 8px;
    }
    .lower-fr ul li a, .lower-fr p {
        font-size: 13px;
    }
    .services-right .inner-ser p {
        min-height: auto;
    }
    .car-details {
        display: none;
    }
    .blogs.property .inner-propery {
        padding: 0;
    }
    button.slick-arrow.slick-prev::before {
        width: 11px;
        height: 11px;
        border: 2px solid #E89732;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next::before {
        width: 11px;
        height: 11px;
        border: 2px solid #E89732;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next {
        right: -6px;
    }
    button.slick-arrow {
        display: block !important;
        position: absolute;
        top: -22px;
        right: 13px;
        left: inherit !important;
    }
    .mob-tour {
        padding-bottom: 41px;
    }
    .product-slider-main .main-sider {
        width: 100%;
        padding-right: 0;
    }
    .product-slider-main .side-sider {
        width: 100%;
        display: none;
    }
    .product-slider-main {
        flex-wrap: wrap;
    }
    .product-slider-main .main-sider img {
        height: 190px;
    }
    .product-detail-page {
        padding-top: 0;
    }

    .slider-desc h4 {
        flex-wrap: wrap;
    }
    .slider-desc h4 {
        font-size: 20px;
        margin: 14px 0 4px;
    }
    .slick-slider button {
        display: none !important;
    }
    .price-table button {
        display: block !important;
    }
    .product-slider-main .main-sider ul.slick-dots li {
        width: 21px;
        background: #ffffffa3;
        height: 3px;
    }
    .product-slider-main .main-sider ul.slick-dots li.slick-active {
     
        background: #fff;
     
    }
    .product-slider-main .main-sider ul.slick-dots {
        bottom: 9px !important;
    }
    .slider-desc h4 label {
        margin: 8px 0;
    }
    ul.amnites-faeture {
        flex-wrap: wrap;
    }
    ul.amnites-faeture li span {
        padding: 7px 9px;
    }
    ul.amnites-faeture li {
        margin-right: 7px;
        margin-bottom: 16px;
    }
    ul.amnites.aminity-wh-img li {
        margin-right: 21px;
        margin-bottom: 17px;
        min-width: 140px;
    }
    .price-table {
        margin-top: 30px;
    }
    .you-like-slider h3 {
        font-family: 'Glitten';
        font-size: 22px;
    }
    .you-like-slider h4 {
        font-size: 17px;
    }
    .you-like-slider {
        margin-bottom: 20px;
    }
    h5.amp-loc {
        font-size: 14px;
        position: relative;
        padding-left: 30px;
    }
    h5.amp-loc img {
        position: absolute;
        left: 0;
    }
    ul.amnites li {
        font-size: 14px;
        min-width: 100px;
    }
    ul.amnites-faeture {
        margin-bottom: 17px;
    }
    ul.list-gyr li, p {
        font-size: 14px;
    }
    .sale-btn a {
       
        font-size: 16px;
        padding: 11px 0 6px;
    }
    .pagination ul li a {
        font-size: 16px;
    }
    .list-filter h3 {
        font-size: 20px;
    }
    .pagination ul {
        margin-bottom: 20px;
    }
    .modal-body {
        padding: 0;
    }
    .modal-body img {
        width: 100%;
    }
    .modal-dialog {
        max-width: 100%;
    }
    .filter-main {
        min-width: 320px;
        padding: 14px;
    }
    .filter-list ul li {
        padding: 7px 5px 4px;
        margin-right: 6px;
        margin-bottom: 7px;
    }
    .page-modal h4 {
        font-size: 23px;
        margin-bottom: 18px;
    }
    .page-modal form .from-group {
        width: 100%;
        margin-bottom: 15px;
    }
    .page-modal form .from-group input {
        height: 43px;
    }
    .modal-dialog {
        max-width: 100% !important;
    }   
    .page-modal {
        padding: 16px 16px;
    }
    .product-detail-page .modal-header button {
        display: block !important;
    }
    .modal-header {
        right: 10px;
        top: 17px;
    }
}
