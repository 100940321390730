.seacrh-bn .seacrhtime .srchtab img {
    width: auto;
}
.seacrh-bn {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 30px 40px;
}
.seacrhtime {
    display: flex;
    align-items: center;
    width: 85%;
}
.srch-btn {
    width: 15%;
    text-align: right;
}
.seacrhtime .srchtab {
    width: 33.33%;
    font-family: 'Raleway', sans-serif;
    position: relative;
}
.seacrhtime .srchtab::before {
    content: "";
    width: 1px;
    height: 50px;
    background: #ababab8c;
    position: absolute;
    left: -112px;
    top: 6px;
}
.seacrhtime .srchtab:first-child::before {
    display: none;
}
.seacrhtime .srchtab label {
    width: 100%;
    font-size: 14px;
    color: #000;
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 7px;
}
.seacrhtime .srchtab span {
    font-size: 18px;
    color: #000;
    opacity: 0.8;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}
.seacrhtime .srchtab span img {
    margin-right: 10px;
}
.srch-btn button {
    background: #E89732;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    line-height: 23px;
    border: 0;
    padding: 18px 54px;
}