


   /*--form-css--*/
   .contact-from {
    padding-top: 100px;
    padding-bottom: 100px;
}
.contact-from form {
  max-width: 1050px;
  margin: 0 auto;
  box-shadow: 0 0 10px 1px #ddd;
  border-radius: 10px;
  padding: 50px;
}
.contact-from form .form-group label {
  width: 100%;
  font-size: 18px;
  margin-bottom: 3px;
}
.contact-from form .form-group input {
  width: 100%;
  border: 0;
  height: 48px;
  background: #F4F4F4;
  border-radius: 3px;
  padding: 0 15px;
  outline: none;
}
.contact-from form .form-group textarea {
  height: 153px;
  width: 100%;
  resize: none;
  background: #F4F4F4;
  border: 0;
  border-radius: 3px;
  padding: 14px 15px;
  outline: none;
}
.contact-from .head-line {
  padding-bottom: 50px;
}
.contact-from form .form-group input[type="submit"] {
  background: #FDC886;
  margin: 0 auto;
  display: table;
  width: auto;
  padding: 0px 70px;
  border: 0;
  border-radius: 3px;
}

