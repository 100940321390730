/*--global-css--*/
body
{
  font-family: 'Campton-medium' !important;

  font-weight: 400;
  font-style: normal;
  background: #fff;
  /* background-image: url(assets/images/icbackground.png); */
  color: #21323C;
}

h2 {
  font-size: 32px;
  font-weight: 500;
  color: #1A1A1A;
  line-height: 41px;
  position: relative;
}
*
{
  outline: none;
}


.common-btn {
  background: #E89732;
  border: 0;
  width: auto;
  margin-right: auto;
  padding: 12px 72px;
  border-radius: 0px;
  font-size: 21px;
  text-decoration: none;
  outline: none;
  /* font-family: 'Circular Std'; */
  color: #fff;
  position: relative;
  font-size:16px;
  font-weight: 500;
}
.pagination ul {
  margin: 0;
  padding: 0;
  display: flex;
  margin-bottom: 40px;
}
.pagination ul li a.active {
  color: #000;
}
.filter span {
  margin-right: 11px;
}
.modal-dialog {
  max-width: 50% !important;
}
.pagination ul li {
  list-style: none;
  position: relative;
  margin-right: 22px;
}
.list-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.filter select {
  border: 0;
  appearance: none;
  outline: none;
  font-size: 16px;
  color: #000000;
}
.pagination ul li::before {
  content: "/";
  position: absolute;
  left: -15px;
  top: 3px;
}
.pagination ul li:first-child::before {
  display: none;
}
.pagination ul li a {
  color: #454545;
  font-size: 18px;
  text-decoration: none;
}
.common-btn::after {
  content: "";
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0px;
  transform: scale(0);
  transition: 0.5s;
}
div#exampleModal {
  background: #000000a8;
}
.common-btn span {
  position: relative;
  z-index: 1;
}
.common-btn:hover {
  color: #fff;
  transition: 0.5s;
}
.common-btn:hover::after {
  transform: scale(1);
  transition: 0.5s;
}
.banner-page h1 {
  font-size: 105px;
  font-weight: 400;
  line-height: 105px;
  font-family: 'Glitten';
  color: #fff;
}
.container {
  max-width: 1550px !important;
}
p {
  font-size: 16px;
  line-height: 21px;
  color: #00000091;
  /* font-family: 'Circular Std'; */
}
.banner-page p
{
  color: #fff;
}


nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: transparent !important;
  padding: 0;
}


.sale-btn {
  position: relative;
}
.sale-btn a {
  background: #000000db;
  color: #fff;
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 21px;
  padding: 15px 0;
  font-family: 'Campton-medium';
  transform: scale(0);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.sale-btn:hover a {
  transform: scale(1);
  transition: 0.5s;
  text-align: center;
  text-decoration: none;
}
.sale-btn:hover a:hover{
  color: #fff;
}