@media (max-width:767px) {
    .select-property {
        display: none;
    }
    .banner-page h1 {
        font-size: 29px;
        line-height: 56px;
        color: #000;
    }
    .banner-page h1 br {
        display: none;
    }
    .footer {
        overflow: hidden;
    }
    button.slick-arrow {
        display: none !important;
    }
    .container-fluid {
        padding: 0;
    }
    .headerpage .log-btn button {
        font-size: 15px;
        padding: 6px 7px;
        border-radius: 3px;
    }
    .headerpage button.navbar-toggler {
        background: #fff;
        padding: 2px 4px;
        border: 0;
        box-shadow: none;
        outline: none;
        position: absolute;
        right: 0;

    }
    .mbile-slider .slick-track .slick-slide, .mobile-car .slick-track .slick-slide, .mob-tour .slick-track .slick-slide, .blog-slider .slick-track .slick-slide {
        padding: 0 8px;
    }
    .inner-propery p {
        font-size: 13px;
        font-weight: 400;
        padding-right: 0;
        line-height: 19px;
    }
    .mob-tour .inner-ser h4 {
        font-size: 15px;
        margin-top: 12px;
        margin-bottom: 5px;
    }
    .mob-tour .inner-ser a {
        color: #e89732;
        text-decoration: none;
        font-size: 14px;
    }
    .inner-propery {
        margin-bottom: 0;
    }
    .inner-propery h5 {
        font-size: 15px;
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .mob-tour h4 {
        margin-bottom: 20px;
    }
    .mob-tour {
        padding-bottom: 16px;
    }
    .inner-ser p {
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 6px;
        min-height: 71px;
    }
    .inner-propery img {
        height: 170px;
    }
    .log-btn {
        padding-right: 54px;
    }
    body .banner-page {
        min-height: 390px;
        background-size: cover;
    }
    .banner-data p {
        display: none;
    }
    .banner-data {
        top: 212px;
    }
    div#navbarSupportedContent {
        position: absolute;
        top: 43px;
        left: 0;
        right: 0;
        background: #fff;
        padding: 13px 0 6px 1px;
    }
    .headerpage ul.navbar-nav.me-auto li a {

        color: #5a5a5a !important;
    }
    .mob-tour {
        padding-top: 18px;
    }
    .blogs.property {
        padding-top: 0px;
        padding-bottom: 25px;
    }
   body h4 {
        font-size: 17px;
    }
    .mbile-slider .slick-track, .explore-data-mobile .slick-track, .mobile-car .slick-track, .mob-tour .slick-track, .blog-slider .slick-track  {
        position: relative;
        left: 13px;
    }
    .explore-inner {
        padding: 0;
    }
    .tour-services.property {
        padding-top: 15px;
    }
    .property h4 {
        font-size: 17px;
        font-weight: 600;
        text-align: left;
    }
    .explore-data-mobile .slick-track .slick-slide {
        padding: 0 8px;
    }
    h4.common-heading {
        margin-bottom: 9px;
        font-size: 17px;
        margin-top: 21px;
    }
    .explore-data-mobile {
        margin-bottom: 20px;
    }
    .top-property-mob {
        margin-top: -79px;
    }
    .services {
        padding-top: 30px;
    }
    h2 {
        font-size: 29px;
        line-height: 33px;
    }
    .services-left p {
        font-size: 16px;
    }
    br
    {
        display: none;
    }
    .services-right {
        padding: 15px 0 0;
        border: 0;
    }
    .services-right .inner-ser {
        width: 100%;
    }
    .services-right {
        flex-wrap: wrap;
        text-align: center;
    }
    .services-right .inner-ser p {
        font-size: 15px;
        padding-right: 0;
        line-height: 22px;
    }
    .services-right .inner-ser a {
        font-size: 16px;
    }
    .inner-ser h4 {
        font-size: 18px;
    }
    .services-right .inner-ser {
        margin-bottom: 34px;
    
    }
    .car-services.property {
        padding-top: 10px;
    }
    .our-app {
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .our-app h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .our-app p {
        font-size: 16px;
        margin: 0px auto 16px;
        line-height: 21px;
    }
    img.frame-one, img.frame-two {
        display: none;
    }
    .playstore {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .our-app .inner-play {
        padding-bottom: 0;
    }
    .register-property img {
        display: none;
    }
    .register-property {
        padding-bottom: 30px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .register-property h2 {
        font-size: 29px;
        line-height: 34px;
        margin-bottom: 2px;
    }
    .register-property p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 auto 18px;
    }
    .footer {
        padding: 40px 0px 17px;
    }
    .register-property a {
        font-size: 16px;
    }
    .fotter-menu {
        padding-top: 14px;
        padding-bottom: 10px;
    }
    .footer-left a {
        font-size: 28px;
        padding-bottom: 8px;
    }
    .playstore img {
        max-width: 130px;
    }
    .footer-left {
        padding-right: 0;
    }
    .footer-menu h5 {
        font-size: 19px;
        margin-bottom: 9px;
        margin-top: 15px;
    }
    .footer-menu.social {
        padding-top: 13px;
    }

    .lower-fr ul li {
        margin-right: 8px;
    }
    .lower-fr ul li a, .lower-fr p {
        font-size: 13px;
    }
    .services-right .inner-ser p {
        min-height: auto;
    }
    .car-details {
        display: none;
    }
    .blogs.property .inner-propery {
        padding: 0;
    }
    button.slick-arrow.slick-prev::before {
        width: 11px;
        height: 11px;
        border: 2px solid #E89732;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next::before {
        width: 11px;
        height: 11px;
        border: 2px solid #E89732;
        border-top: 0;
    border-left: 0;
    }
    button.slick-arrow.slick-next {
        right: -6px;
    }
    button.slick-arrow {
        display: block !important;
        position: absolute;
        top: -22px;
        right: 13px;
        left: inherit !important;
    }
    .mob-tour {
        padding-bottom: 41px;
    }
    .product-slider-main .main-sider {
        width: 100%;
        padding-right: 0;
    }
    .product-slider-main .side-sider {
        width: 100%;
        display: none;
    }
    .product-slider-main {
        flex-wrap: wrap;
    }
    .product-slider-main .main-sider img {
        height: 190px;
    }
    .product-detail-page {
        padding-top: 0;
    }

    .slider-desc h4 {
        flex-wrap: wrap;
    }
    .slider-desc h4 {
        font-size: 20px;
        margin: 14px 0 4px;
    }
    .slick-slider button {
        display: none !important;
    }
    .price-table button {
        display: block !important;
    }
    .product-slider-main .main-sider ul.slick-dots li {
        width: 21px;
        background: #ffffffa3;
        height: 3px;
    }
    .product-slider-main .main-sider ul.slick-dots li.slick-active {
     
        background: #fff;
     
    }
    .product-slider-main .main-sider ul.slick-dots {
        bottom: 9px !important;
    }
    .slider-desc h4 label {
        margin: 8px 0;
    }
    ul.amnites-faeture {
        flex-wrap: wrap;
    }
    ul.amnites-faeture li span {
        padding: 7px 9px;
    }
    ul.amnites-faeture li {
        margin-right: 7px;
        margin-bottom: 16px;
    }
    ul.amnites.aminity-wh-img li {
        margin-right: 21px;
        margin-bottom: 17px;
        min-width: 140px;
    }
    .price-table {
        margin-top: 30px;
    }
    .you-like-slider h3 {
        font-family: 'Glitten';
        font-size: 22px;
    }
    .you-like-slider h4 {
        font-size: 17px;
    }
    .you-like-slider {
        margin-bottom: 20px;
    }
    h5.amp-loc {
        font-size: 14px;
        position: relative;
        padding-left: 30px;
    }
    h5.amp-loc img {
        position: absolute;
        left: 0;
    }
    ul.amnites li {
        font-size: 14px;
        min-width: 100px;
    }
    ul.amnites-faeture {
        margin-bottom: 17px;
    }
    ul.list-gyr li, p {
        font-size: 14px;
    }
    .sale-btn a {
       
        font-size: 16px;
        padding: 11px 0 6px;
    }
    .pagination ul li a {
        font-size: 16px;
    }
    .list-filter h3 {
        font-size: 20px;
    }
    .pagination ul {
        margin-bottom: 20px;
    }
    .modal-body {
        padding: 0;
    }
    .modal-body img {
        width: 100%;
    }
    .modal-dialog {
        max-width: 100%;
    }
    .filter-main {
        min-width: 320px;
        padding: 14px;
    }
    .filter-list ul li {
        padding: 7px 5px 4px;
        margin-right: 6px;
        margin-bottom: 7px;
    }
    .page-modal h4 {
        font-size: 23px;
        margin-bottom: 18px;
    }
    .page-modal form .from-group {
        width: 100%;
        margin-bottom: 15px;
    }
    .page-modal form .from-group input {
        height: 43px;
    }
    .modal-dialog {
        max-width: 100% !important;
    }   
    .page-modal {
        padding: 16px 16px;
    }
    .product-detail-page .modal-header button {
        display: block !important;
    }
    .modal-header {
        right: 10px;
        top: 17px;
    }
}