.filter {
    position: relative;
}
.filter-main {
    position: absolute;
    padding: 24px;
    background: #fff;
    z-index: 1;
    min-width: 390px;
    right: 0;
    top: 150px;
    box-shadow: 0 0 10px 1px #ddd;
    opacity: 0;
    backface-visibility: hidden;
    transition: 0.5s;
}

.filter.active .filter-main {
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    top: 43px;
}
.list-filter button.filter-btn {
    font-size: 15px;
    width: 100%;
    text-align: center;
    color: #E89732;
    border: 1px solid #E89732;
    padding: 11px 0;
}
span.range-slider__wrap {
    margin-bottom: 16px;
}
.filter-list ul li {
    background: #F9F9F9;
    border: 1px solid #BDC2C5;
    padding: 7px 11px;
    text-align: center;
    list-style: none;
    margin-right: 15px;
    font-size: 12px;
    color: #00000094;
    margin-bottom: 14px;
}
.filter-list ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.list-filter button span {
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.filter-list h6 {
    font-size: 14px;
    font-weight: 700;
}
.list-filter button {
    background: #fff;
    border: 0;
    padding: 0;
}
.filter-main::before {
    position: absolute;
    top: -14px;
    background-image: url(../../assets/images/ar.png);
    width: 32px;
    height: 26px;
    right: 20px;
    background-repeat: no-repeat;
    content: "";
    background-size: 100%;
}