/*--footer--*/
.footer {
  background: #21323C;
  padding: 80px 100px 17px;
}
  .footer-left ul {
    padding: 0;
    margin: 0;
  }
  .footer-left ul li {
    font-size: 14px;
    list-style: none;
    color: #0000007d;
    margin-bottom: 9px;
  }
  .footer-left ul li i {
    min-width: 24px;
    color: #000;
  }
  .footer-left {
    padding-right: 60px;
  }
  .footer-menu h5 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 17px;
    opacity: 0.5;
}
  .footer-menu ul {
    padding: 0;
    margin: 0;
  }
  img.ft-img-four {
    position: absolute;
    right: 0;
    bottom: 0;
}
  img.ft-img-three {
    position: absolute;
    left: 140px;
    bottom: 0;
}
  .register-property {
    position: relative;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 100px;
    margin-bottom: 60px;
}
.register-property h2 {
  font-size: 70px;
  line-height: 77px;
  margin-bottom: 21px;
  color: #fff;
}
.register-property p {
  font-size: 21px;
  line-height: 25px;
  max-width: 670px;
  margin: 0 auto 40px;
  color: #fff;
  opacity: 0.6;
  font-family: 'Campton-light';
}
  .footer-menu ul li {
    list-style: none;
    margin-bottom: 10px;
  }
  
  .footer-menu ul li a {
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Campton-light';
    opacity: 0.5;
  }
  .footer-menu ul i {
    color: #000;
  } 
  .footer-menu.social ul {
    display: flex;
  }
  .footer-menu.social ul li {
    margin-right: 17px;
  }
  .footer-menu.social ul li i {
    font-size: 19px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100px;
}
  img.ft-img-one {
    position: absolute;
    top: 0;
    left: 0;
}
img.ft-img-two {
  position: absolute;
  top: 0;
  right: 200px;
}
.fotter-menu {
  border-top: 1px solid #dddddd4d;
  padding-top: 53px;
  padding-bottom: 30px;
}
.fotter-menu p {
  color: #fff;
  font-weight: 400;
  opacity: 0.5;
  font-family: 'Campton-light';
}
.footer-left a {
  font-size: 45px;
  color: #fff;
  opacity: 0.5;
  padding-bottom: 28px;
  display: table;
  font-family: 'Campton-light';
}
.lower-fr {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dddddd4d;
  padding-top: 21px;
}
.lower-fr p {
  color: #fff;
  font-size: 16px;
  opacity: 0.5;
  font-family: 'Campton-light';
  margin: 0;
}
.lower-fr ul {
  padding: 0;
  margin: 0;
  display: flex;
  padding-left: 40px;
}
.lower-fr ul li {
  margin-right: 38px;
  list-style: none;
}
.lower-fr ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  opacity: 0.5;
  font-family: 'Campton-light';
}
.register-property a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid;
}
.register-property a i {
  margin-left: 11px;
  font-size: 16px;
}