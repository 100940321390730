.avail-section {
    padding: 120px 0;
}
.avail-section .container {
    max-width: 1060px !important;
}
.avail-left h4 {
    font-size: 40px;
    font-weight: 400;
    font-family: 'Glitten';
}
.avail-left p {
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
    font-weight: 500;
    color: #21323C;
    font-family: 'Raleway', sans-serif;
    padding-right: 100px;
    margin-bottom: 36px;
    margin-top: 14px;
}
.avail-section button.common-btn {
    background: #fff;
    color: #E89732;
    border: 1px solid #E89732;
}