.blogs.property {
    padding-top: 100px;
    padding-bottom: 60px;
}
.blogs.property .inner-propery {
    padding: 0 20px;
}
.blogs.property .inner-propery p a {
    color: #e89732;
    text-decoration: none;
    font-weight: 500;
}
.blog-slider {
    max-width: 1400px;
    margin: 0 auto;
}
.blogs.property .inner-propery p {
    padding: 0;
}
.blogs.property .inner-propery img {
    height: 191px;
}
button.slick-arrow.slick-prev {
    left: -50px;
}
button.slick-arrow.slick-next
{
    right: -50px;
}
.blogs.property h4 {
    width: 100%;
}
button.slick-arrow.slick-prev::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #828282;
    display: flex;
    border-top: 0;
    border-left: 0;
    transform: rotate(135deg);
}
button.slick-arrow.slick-next::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #828282;
    display: flex;
    border-top: 0;
    border-left: 0;
    transform: rotate(-45deg);
}